import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from '../components/layout';
import SEO from '../components/seo';
import PostListing from '../components/PostListing';
import config from '../../data/SiteConfig';

const TagPageTemplate = ({ pageContext, data }) => {
  
    const { tag } = pageContext;
    const { edges, totalCount } = data.allMarkdownRemark;

    const post = totalCount > 1 ? 'posts' : 'post';

    return (
      <DefaultLayout>
        <SEO
            title={`Tagged in ${tag.charAt(0).toUpperCase() + tag.slice(1)}`}
            description={config.siteTitle}
            keywords={[`gobinda`, `tag`, `blogs`, `thakur`, tag]}
        />
        <div className="pages">
            <h3>{`${totalCount} ${post} tagged with "${tag}"`}</h3>
        </div>
        <PostListing posts={edges} />
      </DefaultLayout>
    );
}

export default TagPageTemplate;

export const pageQuery = graphql`
  query TagPageTemplate($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
